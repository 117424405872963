import React from 'react'
import styled from 'styled-components'
import Button from './Button'

const ItemDiv = styled.div`
  width: 100%;
  // display: flex;
`

const ImgDiv = styled.div`
  width: 100%;
  padding: 15px;
`

const ProductImg = styled.img`
  width: 100%;
  border: 1px solid black;
`

const InfoDiv = styled.div`
  width: 100%;
  padding: 0 15px 25px 15px;
`

const ProductName = styled.h2`
  font-size: 18px;
  font-weight: 800;
`

const Price = styled.small`
  font-size: 14px;
  font-weight: 800;
  color: #1515fa;
  padding-left: 5px;
`

const Disclaimer = styled.div`
  font-size: 12px;
  font-weight: 300;
  color: #1515fa;
  padding-left: 5px;
  padding-bottom: 10px;
`

const Description = styled.h4`
  font-size: 14px;
`

// image
// product name
// description
// price
// url

// {image: "", productName: "", description: "", price: "", url: ""}

export default function StoreItem (props) {

  return (
    <>
      <ItemDiv>
        <ImgDiv>
          <ProductImg src={props.image} />
        </ImgDiv>
        <InfoDiv>
          <ProductName>
            {props.productName}
            <Price>
              (${props.price})
            </Price>
          </ProductName>
          <Description>{props.description}</Description>
          <Disclaimer>**Free Shipping**</Disclaimer>
          <Button onClick={() => window.open(props.url, "_blank")}>Add to Cart</Button>
        </InfoDiv>
      </ItemDiv>
    </>
  )

}