import React, { useState, setState, useEffect, useContext, Component, useRef } from 'react'
import ReactDOM from 'react-dom'
import Button from './Button'
import AudioMotionAnalyzer from 'audiomotion-analyzer';
import styled from 'styled-components'
import safari_active from '../images/safari_active.gif'
import safari_inactive from '../images/safari_inactive.gif'
import loader from '../images/loader.gif'


const Contents = styled.div`
  display: flex;
  padding: 10px;
  position: relative;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const Title = styled.h2`
  font-size: 16px;
  padding-top: 10px;
`

const Time = styled.h3`
  font-size: 14px;
  position: absolute;
  padding-top: 20px;
  padding-right: 10px;
  top: 0;
  right: 0;
`

const SliderBar = styled.div`
  margin: 10px;
  background-color: white;
  height: 15px;
  position: relative;
`

const ButtonDiv = styled.div`
  margin: 7px;
  padding-top: 20px;

  Button{
    margin: 0 3px;
  }
`

const VisualizerSpan = styled.div`
  float: right;
`

const LoadingDiv = styled.div`
  width: 100%;
  text-align: center;
  font-size: 22px;
  padding-top: 20px;
`

const Slider = styled.div`
  background-color: rgb(206, 208, 207);
  width: 12px;
  height: 25px;
  position: absolute;
  top: -5px;
  border-left-color: rgb(255, 255, 255);
  border-top-color: rgb(255, 255, 255);
  border-right-color: rgb(5, 6, 8);
  border-bottom-color: rgb(5, 6, 8);
  box-shadow: rgb(223, 224, 227) 1px 1px 0px 1px inset, rgb(136, 140, 143) -1px -1px 0px 1px inset;
  left: ${props => props.complete};
`

const Gif = styled.img`
  width: 100%;
  height: 145px;
`

const Loader = styled.img`
  width: 100px;
  height: 100px;
  display: block;
  text-align: center;
  margin: 25px auto;
`

const visualizerStyle = {
  marginTop: '-15px',
  transform: 'rotate(180deg) scaleX(-1)',
};

const visualizerStyleNone = {
  display: 'none',
};

const windowStyle = {
  maxWidth: '550px',
};

const windowStyleVisualizer = {
  maxWidth: '100%',
  paddingBottom: '0 !important'
};

const visualizerButtons = {
  paddingTop: '0px',
};

const mobileTitle = {
  width: '80%',
};


export default function MusicPlayer(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [tracks, setTracks] = useState([]);
  const [stream, setStream] = React.useState(null);
  const [trackNumber, setTrackNumber] = React.useState(0);
  const [seconds, setSeconds] = React.useState(0);
  const [isActive, setIsActive] = React.useState(false);
  const [audioMotion, setAudioMotion] = React.useState(null);
  const [audioMotion2, setAudioMotion2] = React.useState(null);
  const sliderRef = React.useRef(null)
  const [visualizer, setVisualizer] = React.useState(false);
  const [prevTransform, setPrevTransform] = React.useState(null)
  const canvasWidth = window.innerWidth;
  const canvasHeight = (window.innerHeight - 100)/2;
  const isSafari = window.safari !== undefined;
  const mobile = (window.innerWidth < 500);

  const displayVisualizer = !(isSafari || mobile);

  let playlistId = "1044765946"
  let clientId = "c5a171200f3a0a73a523bba14a1e0a29"

  useEffect(() => {
    var proxyUrl = "https://protected-mesa-83600-c5d8811d6af4.herokuapp.com/"
    var targetUrl = `https:\//api.soundcloud.com/playlists/${playlistId}?client_id=${clientId}`
    fetch(proxyUrl + targetUrl)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          shuffleTracks(result.tracks);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [])

  function shuffleTracks(array) {
    let counter = array.length;

    while (counter > 0) {
        let index = Math.floor(Math.random() * counter);
        counter--;
        let temp = array[counter];
        array[counter] = array[index];
        array[index] = temp;
    }

    setTracks(array);
    setStream(`https://api.soundcloud.com/tracks/${array[0].id}/stream?client_id=${clientId}`);
    if(displayVisualizer){createAudioMotion();}
    setIsActive(true);
  }

  function nextTrack() {
    let trackNo = (trackNumber === (tracks.length - 1)) ? 0 : trackNumber + 1;
    setStream(`https://api.soundcloud.com/tracks/${tracks[trackNo].id}/stream?client_id=${clientId}`)
    setTrackNumber(trackNo);
    setSeconds(0);
    playTrack();
  }

  function prevTrack() {
    setStream(`https://api.soundcloud.com/tracks/${tracks[trackNumber - 1].id}/stream?client_id=${clientId}`);
    setTrackNumber(trackNumber - 1);
    setSeconds(0);
    playTrack();
  }

  function pauseTrack() {
    var player = document.getElementById("audio");
    if (displayVisualizer) {
      var player2 = document.getElementById("audio2");
    }
    player.pause();
    if (displayVisualizer) {player2.pause()};
    setIsActive(false);
  }

  function playTrack() {
    let interval = null;
    var player = document.getElementById("audio");
    if (displayVisualizer) {
      var player2 = document.getElementById("audio2");
      visualizer === true ? player2.volume = 0.03 : player2.volume = 0;
    }

    interval = setInterval(() => {
      player.play();
      if (displayVisualizer) {player2.play()};
      setIsActive(true);
      clearInterval(interval);
    }, 300);
    return () => clearInterval(interval);
  }


  function createAudioMotion() {
    const audioOptions = {
      source: document.getElementById('audio'),
      start: true,
      width: canvasWidth,
      height: canvasHeight,
      gradient: 'prism',
      lineWidth: 0.5,
      fillAlpha: 0.5,
      barSpace: 0.3,
      mode: 3,
      showScale: false,
      showLeds: true,
    };
    setAudioMotion(new AudioMotionAnalyzer(document.getElementById('audio-player'),audioOptions));

    const audioOptionsVisualizer = {
      source: document.getElementById('audio2'),
      start: true,
      width: canvasWidth,
      height: canvasHeight,
      gradient: 'prism',
      lineWidth: 0.5,
      fillAlpha: 0.5,
      barSpace: 0.3,
      mode: 3,
      minDecibels: -99,
      maxDecibels: -55,
      showScale: false,
      showLeds: true,
    };
    setAudioMotion2(new AudioMotionAnalyzer(document.getElementById('audio-player2'),audioOptionsVisualizer));
  }

  function toggleVisualizer() {
    setVisualizer(!visualizer);
    var player2 = document.getElementById("audio2");
    !visualizer ? player2.volume = 0.03 : player2.volume = 0;
    !visualizer ? enableVisualizer() : disableVisualizer();
  }

  function enableVisualizer(){
    var parent = document.getElementById('CDPlayerDragHandle').parentElement.parentElement;
    setPrevTransform(parent.style.transform)
    parent.style.transform = "translate(0px,0px)";
    parent.style.width = window.innerWidth.toString() + 'px';
    parent.style.height = (window.innerHeight - 34).toString() + 'px';
    parent.style.paddingBottom = '0';
  }

  function disableVisualizer(){
    var parent = document.getElementById('CDPlayerDragHandle').parentElement.parentElement;
    parent.style.transform = prevTransform;
    parent.style.width = props.defaultWidth.toString() + 'px';
    parent.style.height = props.defaultHeight.toString() + 'px';
  }

  function convertMilliseconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
  }

  function convertSeconds(secs) {
    var minutes = Math.floor(secs / 60);
    var seconds = (secs % 60).toFixed(0);
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
  }

  function calculateSlider(seconds, duration, width) {
    var elapsed = seconds;
    var total = (duration / 1000);
    var offset = (Math.floor((elapsed/total)*width)-5);
    return offset.toString() + "px"
  }

  function reset() {
    setSeconds(0);
    setIsActive(false);
  }


  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds + 1);
        if (seconds === 0){playTrack()};
        if (seconds === Math.floor(tracks[trackNumber].duration/1000)){nextTrack()};
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);


  if (error) {
    return <LoadingDiv>CD was scratched — currently fixing and getting a new one.</LoadingDiv>
  } else if (!isLoaded) {
    return (
      <LoadingDiv>
        Loading disc...
        <Loader src={loader} />
      </LoadingDiv>
    )
  } else {
    return (
      <div style={visualizer ? windowStyleVisualizer : windowStyle} >
        <audio id="audio" src={stream} crossOrigin="anonymous" />
        {displayVisualizer && <audio id="audio2" src={stream} crossOrigin="anonymous" />}
        {!displayVisualizer ? (
          <>
            {isActive ? <Gif src={safari_active} /> : <Gif src={safari_inactive} />}
          </>
        ) : (
          <>
            <div id="audio-player"></div>
            <div id="audio-player2" style={visualizer ? visualizerStyle : visualizerStyleNone} ></div>
          </>
        )}
        {tracks.length > 0 && !visualizer && (
          <>
            <Contents>
              <Title style={mobile ? mobileTitle : null}>{tracks[trackNumber].title}</Title>
              <Time>{convertSeconds(seconds)} / {convertMilliseconds(tracks[trackNumber].duration)}</Time>
            </Contents>
            <SliderBar ref={sliderRef} >
              <Slider complete={calculateSlider(seconds, tracks[trackNumber].duration, sliderRef.current ? sliderRef.current.offsetWidth : 525)} />
            </SliderBar>
          </>
        )}
        <ButtonDiv style={visualizer ? visualizerButtons : null}>
          <Button
            fontAwesome="backward"
            disabled={trackNumber === 0}
            onClick={() => prevTrack()}
          />
          <Button
            fontAwesome="forward"
            onClick={() => nextTrack()}
          />
          <Button
            fontAwesome="pause"
            disabled={!isActive}
            onClick={() => pauseTrack()}
          />
          <Button
            fontAwesome="play"
            disabled={isActive}
            onClick={() => playTrack()}
          />
          {displayVisualizer && (
            <VisualizerSpan>
              <Button
                text={visualizer ? "Disable Visualizer" : "Enable Visualizer"}
                onClick={() => toggleVisualizer()}
              />
            </VisualizerSpan>
          )}
        </ButtonDiv>
      </div>
    );
  }
}
