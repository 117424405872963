import React from 'react'
import styled from 'styled-components'
import { Rnd } from 'react-rnd';
import Button from './Button'
import logo_cube from '../images/logo_cube.gif'
import powered_by from '../images/powered_by_soundcloud.png'
import tyler from '../images/tyler.png'

const GifDiv = styled.div`
  text-align: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const Gif = styled.img`
  width: 50%;
  max-width: 200px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const TextDiv = styled.div`
  width: 100%;
  margin-top: 15px;
  font-size: 12px;
  padding: 15px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const Divider = styled.div`
  margin: 20px 0;
  display: block;
  border-top: 2px solid #929292;
  border-bottom: 1px solid white;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const UserDiv = styled.div`
  display: flex;
  height: 50px;
`
const AdminDiv = styled.div`
  padding-top: 10px;
  padding-left: 15px;
`

const UserImg = styled.img`
  width: 50px;
  border-radius: 10px;
`

const Link = styled.a`
  color: blue;
  text-decoration: underline;
  text-decoration-color: blue;

  &:hover {
    text-decoration-color: blue;
  }

  p {
    color: blue;
    text-decoration-color: blue;
  }
`

const noMargin = {
  marginBottom: '0px',
};

const soundImg = {
  width: '80px',
};

const linkStyle = {
  'color': 'blue',
  'textDecoration': 'underline',
  'textDecorationColor': 'blue'
}

export default function About (props) {

  return (
    <>
      <GifDiv>
        <Gif src={logo_cube} draggable="false" />
      </GifDiv>
      <TextDiv>
        <p style={noMargin}>Big Desk Energy</p>
        <p>Copyright © 2023</p>
        <p>Conceived in the Hamptons, taken shape in the form of a Spotify playlist, and fueled by the novel coronavirus pandemic. Big Desk Energy is whatever you make of it—so boot your desktop, turn up the volume, and stay a while. Oh, and follow the OG playlist on Spotify <a href="https://open.spotify.com/playlist/5s8443tfYUq3LLARJwGeYP" target="_blank" style={linkStyle}>here</a>.</p>
        <img style={soundImg} src={powered_by} />
        <Divider />
        <UserDiv>
          <a href="https://www.twitter.com/denk_tweets" target="_blank">
            <UserImg src={tyler} draggable="false" />
          </a>
          <AdminDiv>
            <Link href="https://www.twitter.com/denk_tweets" target="_blank"><p style={noMargin}><strong>Tyler Denk</strong></p></Link>
            <p>Admin User</p>
          </AdminDiv>
        </UserDiv>
      </TextDiv>
    </>
  )

}