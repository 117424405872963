import React from 'react'
import styled from 'styled-components'
import { Rnd } from 'react-rnd';
import Button from './Button'
import StoreItem from './StoreItem'

// load in images for items
import laptop_img from '../images/laptop_sleeve.jpg'
import hat_img from '../images/hat.jpg'
import windows_img from '../images/windows_shirt.jpg'
import button_img from '../images/button_shirt.jpg'
import coffee_img from '../images/coffee_mug.jpg'
import crewneck_img from '../images/crewneck.jpg'





const Grid = styled.div`
  height: 100%;
  width: 100%;
  overflow: scroll;
  margin: auto;
  font-size: 16px;
  overflow-x: hidden;
  resize: none;
  outline: none;
  border: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;


  &::-webkit-scrollbar {
   width: 1em;
   height: 1em;
   background: linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), white;
    background-repeat: repeat, repeat;
    background-position: 0px 0, 5px 5px;
    transform-origin: 0 0 0;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
    background-size: 2px 2px, 2px 2px;
    box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
   background-color: rgb(206, 208, 207);
    color: rgb(5, 6, 8);
    border-left-color: rgb(255, 255, 255);
    border-top-color: rgb(255, 255, 255);
    border-right-color: rgb(5, 6, 8);
    border-bottom-color: rgb(5, 6, 8);
    box-shadow: rgb(223,224,227) 1px 1px 0px 0px inset, rgb(136,140,143) -1px 0px 0px 1px inset;
    position: relative;
    border: 1px;
  }

  focus {
    outline: 0;
  }
`

const Row = styled.div`
  display: flex;
`;


const Col = styled.div`
  flex: ${(props) => props.size};
  border: 1px dotted black;
`;

const EmptyCol = styled.div`
  flex: ${(props) => props.size};
`;


export default function Store (props) {

  const inventory = [
    {
      image: laptop_img,
      productName: "BDE Laptop Sleeve",
      description: "Treat your laptop like the queen it is.",
      price: "24",
      url: "https://shop.bigdeskenergy.com/en-usd/products/bde-laptop-sleeve",
    },
    {
      image: hat_img,
      productName: "BDE Hat",
      description: "Worn by champions in and outside of the office.",
      price: "29",
      url: "https://shop.bigdeskenergy.com/products/bde-hat",
    },
    {
      image: windows_img,
      productName: "Windows Shirt",
      description: "More windows more problems.",
      price: "29",
      url: "https://shop.bigdeskenergy.com/products/windows-shirt",
    },
    {
      image: coffee_img,
      productName: "OG Mug",
      description: "Drink like you have a big desk.",
      price: "14",
      url: "https://shop.bigdeskenergy.com/products/og-mug?source=dashboard",
    },
    {
      image: crewneck_img,
      productName: "Crewneck",
      description: "The most comfortable desk attire.",
      price: "34",
      url: "https://shop.bigdeskenergy.com/products/crewneck",
    },
    {
      image: button_img,
      productName: "Big Button Tee",
      description: "So everyone can push your buttons at work.",
      price: "29",
      url: "https://shop.bigdeskenergy.com/products/big-button-tee",
    }
  ];

  const columns = Math.floor(props.width / 250);
  const rows = Math.ceil((1.0 * inventory.length)/ (1.0 * columns));

  const col = [];
  const final = []

  function displayInventory() {
    inventory.map((item, index) => {
      col.push(<Col size={1} key={index}><StoreItem image={item.image} productName={item.productName} description={item.description} price={item.price} url={item.url} key={item.url} /></Col>);
    })

    if(inventory.length > columns){
      var leftover = ((columns*rows)-inventory.length);
      for (var i=0; i<leftover; i++){
        col.push(<EmptyCol size={1} ></EmptyCol>)
      };
    }

    var lower = 0;
    var upper = columns;

    for (var j=0; j<rows; j++){
      final.push(<Row>{col.slice(lower,upper)}</Row>);
      lower += columns;
      upper += columns;
    }
    return final;
  }

  return (
    <>
      <Grid>
        {displayInventory()}
      </Grid>
    </>
  )

}